<template>
  <div class="analytics-usage-patients">
    <div class="row tw-h-112">
      <div class="col-sm-9">
        <owl-usage-patients-line-chart
          :is-loading="measureActivityLoading"
          :has-errors="measureActivityHasErrors"
          :series="measureCompletionRateSeries"
          :start="start"
          :end="end"
          :graph-interval="graphInterval"
          chart-type="measureCompletionRate"
        />
      </div>
      <div class="col-sm-3">
        <div class="tw-text-xl tw-text-center tw-text-gray tw-font-medium tw-mt-1">
          Today
        </div>
        <owl-usage-donut-chart
          :is-loading="measureCompletionTodayLoading"
          :series="measureCompletionTodaySeries"
          chart-type="measureCompletionToday"
        />
      </div>
    </div>

    <div class="row tw-h-112">
      <div class="col-sm-9 tw-mt-6">
        <owl-usage-patients-line-chart
          :is-loading="activePatientsLoading"
          :has-errors="activePatientsHasErrors"
          :series="activePatientsSeries"
          :start="start"
          :end="end"
          :graph-interval="graphInterval"
          chart-type="activePatients"
        />
      </div>
      <div class="col-sm-3">
        <owl-usage-donut-chart
          :is-loading="activePatientsTodayLoading"
          :series="activePatientsTodaySeries"
          chart-type="activePatientsToday"
        />
      </div>
    </div>

    <div class="row tw-h-112">
      <div class="col-sm-9 tw-mt-6">
        <owl-usage-patients-line-chart
          :is-loading="measureActivityLoading"
          :has-errors="measureActivityHasErrors"
          :series="measureActivitySeries"
          :start="start"
          :end="end"
          :graph-interval="graphInterval"
          chart-type="measureActivity"
        />
      </div>
      <div class="col-sm-3">
        <owl-usage-donut-chart
          :is-loading="measureActivityTodayLoading"
          :series="measureActivityTodaySeries"
          chart-type="measureActivityToday"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { OwlUsagePatientsLineChart, OwlUsageDonutChart } from 'owl-components'
import {
  FETCH_MEASURE_ACTIVITY,
  FETCH_MEASURE_ACTIVITY_TODAY,
  FETCH_MEASURE_COMPLETION_TODAY,
  FETCH_ACTIVE_PATIENTS,
  FETCH_ACTIVE_PATIENTS_TODAY
} from '@/store/modules/analytics/usage/constants.js'

export default {
  name: 'AnalyticsUsagePatients',
  components: {
    OwlUsagePatientsLineChart,
    OwlUsageDonutChart
  },
  computed: {
    ...mapGetters({
      globalParams: 'getAnalyticsUsageGlobalParams',
      activePatientsLoading: 'getAnalyticsUsageActivePatientsLoadingState',
      activePatientsHasErrors: 'getAnalyticsUsageActivePatientsHasErrors',
      activePatientsParams: 'getAnalyticsUsageActivePatientsParams',
      activePatientsSeries: 'getAnalyticsUsageActivePatientsSeries',
      activePatientsTodayLoading: 'getAnalyticsUsageActivePatientsTodayLoadingState',
      activePatientsTodaySeries: 'getAnalyticsUsageActivePatientsTodaySeries',
      measureActivityLoading: 'getAnalyticsUsageMeasureActivityLoadingState',
      measureActivityHasErrors: 'getAnalyticsUsageMeasureActivityHasErrors',
      measureActivityParams: 'getAnalyticsUsageMeasureActivityParams',
      measureActivitySeries: 'getAnalyticsUsageMeasureActivitySeries',
      measureCompletionRateSeries: 'getAnalyticsUsageMeasureCompletionRateSeries',
      measureActivityTodayLoading: 'getAnalyticsUsageMeasureActivityTodayLoadingState',
      measureActivityTodaySeries: 'getAnalyticsUsageMeasureActivityTodaySeries',
      measureCompletionTodayLoading: 'getAnalyticsUsageMeasureCompletionTodayLoadingState',
      measureCompletionTodaySeries: 'getAnalyticsUsageMeasureCompletionTodaySeries'
    }),
    start () {
      return this.measureActivityParams.criteria.due_date_range.greater_than
    },
    end () {
      return this.measureActivityParams.criteria.due_date_range.less_than
    },
    graphInterval () {
      return this.measureActivityParams.criteria.graph_interval
    }
  },
  watch: {
    globalParams: {
      deep: true,
      handler () {
        this.getPatientsData()
      }
    }
  },
  created () {
    this.getPatientsData()
  },
  methods: {
    getPatientsData () {
      this.$store.dispatch(FETCH_MEASURE_ACTIVITY)
      this.$store.dispatch(FETCH_MEASURE_ACTIVITY_TODAY)
      this.$store.dispatch(FETCH_MEASURE_COMPLETION_TODAY)
      this.$store.dispatch(FETCH_ACTIVE_PATIENTS)
      this.$store.dispatch(FETCH_ACTIVE_PATIENTS_TODAY)
    }
  }
}
</script>
